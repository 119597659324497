.building {
  margin:0;
  display:block;
  
  .views {
    width:100%;
    height:100%;
    > * {
      position:absolute;
      width:100%;
      height:100vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-attachment: fixed;
    }
  }
  
  .views__furnished {
    opacity:1;
    @include transition(opacity 1s linear);
    
    &.active {
      opacity:0;
    }
  }
}

.minimap {
  padding:15px;
  position:absolute;
  bottom:2%;
  right:2%;
  background: rgba(0, 0, 0, 0.1);
  width:400px;
  height:200px;
  @include prefix(box-sizing, border-box, webkit ms);
  
  > div {
    float:left;
    width:30%;
    &:first-child {
      width:70%;
    }
  }
  
  .minimap__title {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color:white;
  }
  
  .minimap__switch {
    p {
      color:white;
      font-size:0.8rem;
      text-transform: none;
      margin-top:30px;
      text-align: center;
    }
  }
  
  .minimap__icon {
    cursor:pointer;
    width:70px;
    height:70px;
    background: url('/skins/jermyn/graphics/icon-furnished.svg') center center no-repeat;
    background-size: 100%;
    margin:20px auto 0 auto;
    
    &.active {
      background: url('/skins/jermyn/graphics/icon-unfurnished.svg') center center no-repeat;
      background-size: 100%;
    }
  }
  
  .minimap__map {
    position: relative;
    width: 240px;
    height: 125px;
    background: url("/skins/jermyn/graphics/icon-floorplan.svg") center center no-repeat;
    background-size: 100%;
  }

  .point {
    .point__icon {
      width: 50px;
      height: 50px;
      background-size: 70% 70%;
      cursor: pointer;
    }
  }
}

@media all and (max-width: 1024px) {
  .minimap {
    right: 0;
    bottom:0;
    
  }
}

@media all and (max-width: 778px) { //iphone6
  .minimap {
    width: 100%;
    height: 150px;
    background: none;
    padding:10px;
    
    .minimap__map {
      width: 230px;
      height: 95px;
      background-size: 230px 95px;
    }

    .minimap__title {
      padding-left:10px;
      margin-bottom:5px;
      font-size: 1.1rem;
    }
    
    .minimap__switch {
      position: relative;
      right: -40px;
    }

    .minimap__switch p {
      font-size: 0.8rem;
      margin-top: 30px;
    }

    .minimap__icon {
      width: 60px;
      height: 60px;
      background-size: 60px 60px;
      margin: 10px auto 0 auto;
      
      &.active {
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
      }
    }
  }
}

@media all and (max-width: 448px) {
  .minimap {
    width:100%;
  }

  .minimap__switch {
    right: auto!important;
  }
}