.masterplan {
	position:relative;
	
	img {
		width:100%;
		height:100%;
		max-width: 1024px;
	}
}

.map_container {
	margin:5% 0 0 5%;
	width:100%;
	height:100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.point {
	position:absolute;
	
	&:hover {
		.point__name {
			opacity:1;
		}
	}
	
	.point__icon {
		width:90px;
		height:90px;
		background-position: center center;
		background-size: 90%;
		background-repeat: no-repeat;
		cursor:pointer;
		
		&.point--left {
			-moz-transform:rotate(180deg);
			-o-transform:rotate(180deg);
			-webkit-transform:rotate(180deg);
			transform:rotate(180deg);
		}
	}
	
	.point__name {
		opacity:0;
		position: absolute;
		bottom: -20px;
		font-size:1.2rem;
		font-weight:bold;
		padding:5px 10px;
		background: rgba(255, 177, 37, 0.8);
		@include transition(opacity .3s linear);
	}
}

@media all and (max-width: 667px) {
	.masterplan img {
		width:70%;
		height:70%;
	}

	.map_container {
		margin:8% 0 0 20%;
	}

	.point {
		.point__icon {
			width:70px;
			height:70px;
		}
		.point__name {
			opacity:1;
			font-size: 0.6em;
			bottom:-10px;
		}
	}
}
@media all and (max-width: 667px) and (orientation : landscape){
	.map_container {
		width:80%;
		height:80%;
		margin:6% 0 0 25%;
	}

}


@media all and (max-width: 448px) {
	.masterplan img {
		width:80%;
		height:80%;
	}

	.map_container {
		margin:8% 0 0 10%;
	}

	.point {
		.point__icon {
			width:50px;
			height:50px;
		}
	}
}
