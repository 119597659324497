@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // Output standard non-prefixed declaration
  #{$property}: $value;
}

@mixin flex-center() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
  transform: translateZ(0);
}

#container{
  opacity:0;
}

html{
  overflow: hidden;
}

html, body {
  opacity:1;
  background: white;
  font-family: 'Nunito', sans-serif;
  margin:0;
  padding:0;
  height: 100%;
  overflow: hidden;
}

h1 {
  color: #ffb125;
  font-size: 2rem;
}

h2{
  font-size: 1.5rem;
  color: #7f807f;
}

h1,h2,h3 {
  margin:4px 0;
  font-weight: 400;
}

p {
  margin:0;
  font-size:1.5rem;
  color: white;
}

@import 'widgets';
@import 'pages';

#content {
  display:block;
  width:100%;
  height:100%;
  overflow: hidden;
  position: relative;
}

#head #logo{
  position: absolute;
  top:0;
  right:0;
  width: 280px;
  height: 64px;
  background: url(../graphics/logo.jpg) no-repeat;
  background-size:cover;
  image-rendering: -webkit-optimize-contrast;
}

#tabletmenu{
  padding-left:0;
  padding-top: 0;
}

#tabletmenu li a {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  margin-bottom:10px;
  padding:0;
}

#tabletmenu li a p{
  font-size:.9rem;
}

#tabletmenu li a:hover {
  color:rgba(255, 255, 255, 0.5);
}

#tabletmenu li img{
  opacity:1;
}


#head #menutoggle,
#head #menutoggle.open,
#head #menutoggle:hover{
  background: url(../graphics/menu.png) center center no-repeat;
  background-size: 64px 64px;
}

#navigation {
  background: none;
}

.mapplic-map {
  position:absolute;
}

iframe {
  border:0;
  z-index:100;
  min-height:800px;
}

#head {
  background:rgba(0, 0, 0, 0.1);;
}

#tabletmenu{
  width:130px;
  overflow:hidden;
}

#tabletmenu li a{
  height:40px;
  line-height:40px;
}

#tabletmenu li div{
  padding-left:20px;
}

@media all and (max-width: 768px) {
  .map_container .pointer h3{
    opacity:1;
  }

  #head {
    height: 44px;
  }

  #head #menutoggle {
    height: 44px;
    width: 44px;
  }

  #head #menutoggle, #head #menutoggle.open, #head #menutoggle:hover {
    background-size: 44px 44px;
  }
}

