.widget.widget--gallery {
  background: rgba(0,0,0,.5);
  position:absolute;
  z-index:100;
  top:0;
  height:100%;
  width:100%;
  @include flex-center;

  display:none;
  
  .widget__gallery {
    background: rgba(0,0,0,0.7);;
    max-width: 90%;
    height:auto;
    padding: 1%;
    
    img {
      width:100%;
      display:block;
      object-fit: cover;
    }
  }
  
  &.active {
    @include flex-center;
  }
}

.owl-stage-outer {
  margin:1px;
}

.owl-item {
  p {
    margin:0;
  }
}

.owl-dots {
  text-align: center;
  vertical-align: middle;
  padding: 10px 0 0 0;

  .owl-dot {
    padding: 10px 8px 0 8px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/skins/jermyn/graphics/dot.png') center center no-repeat;
    background-size:20px 20px;
    cursor: pointer;
    &:last-child {
      margin-right:0;
    }
    &.active {
      background: url('/skins/jermyn/graphics/dot_active.png') center center no-repeat;
      background-size:20px 20px;
    }

  }

}
