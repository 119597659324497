.widget--copy {
  p {
    color: #7f807f;
  }
}

@media all and (max-width: 1024px) {
  .widgets {
    width: 100%;
  }

  .widget--copy h1 {
    position: absolute;
    top: -100px;
    width: 100%;
    display: block;
    font-size: 2.5rem;
    left: 52px;
  }

  .widget--copy h2, .widget--copy p {
    font-size: 2rem;
  }
}

@media all and (max-width: 768px) {
  .widget--copy h1 {
    top: -112px;
  }
}

@media all and (max-width: 667px) { //iphone6

  .widget--copy h1 {
    top: -74px;
    left:42px;
    font-size: 2rem;
  }

  .widget--copy h2, .widget--copy p {
    position:relative;
    display:inline;
    top:-10px;
    margin-right:10px;
    font-size: 1rem;
  }
}

@media all and (max-width: 448px) {
  .widget--copy h1 {
    top: -76px;
    font-size: 1.6rem;
    left: 62px;
  }
}